import axios from 'axios' 
import { getToken } from './getToken'
import { Message } from 'element-ui'
// 创建axios实例
const request = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: 'https://www.allenmath.cn:8770', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    // baseURL: 'http://192.168.124.6:8770', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 10 * 1000, // 请求超时时间
 
})
 
// 请求拦截器
request.interceptors.request.use((config) => {
//token名称以自己的为定，我的是‘satoken’，如果不需要if这里就可以直接删掉
    if (getToken('token')) {
        config.headers['Authorization'] = getToken('token'); //携带token
        config.headers['Content-type'] = 'application/json';
    }
 
    return config;
}, (error) => {
    return Promise.reject(error)
})
 
request.interceptors.response.use((response) => {
//返回码以自己的为定，如果没有安装elementui就换成自己的提示
    let { code, msg } = response.data
    if (code != 200 && code != 401) {
        Message({ message: msg || 'error', type: 'warning' })
    }
    return response.data;//此处可以只返回后端传出的数据（第一层data是axios封装的）
}, (error) => {
    Message({ message: msg || 'error', type: 'warning' })
    return Promise.reject(error)
})
 
export default request;//记得暴露出去