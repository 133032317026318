import request from '@/axios/index'

export function login(data) {
  return request({
    url: '/admin/appLogin',
    method: 'post',
    params: data
  })
}

export function login2(data) {
  return request({
    url: '/admin/appMsgLogin',
    method: 'post',
    params: data
  })
}


export function imgCode(data) {
  return request({
    url: '/captchaWebImage',
    method: 'get',
    params: data
  })
}

export function nation(data) {
  return request({
    url: '/countries/getParamList',
    method: 'post',
    params: data
  })
}

export function filtrate1(data) {
  return request({
    url: '/bureau/getParamList',
    method: 'post',
    params: data
  })
}

export function filtrate2(data) {
  return request({
    url: '/subject/getParamList',
    method: 'post',
    params: data
  })
}

export function list(data) {
  return request({
    url: '/fileInfo/getList',
    method: 'post',
    params: data
  })
}

export function load(data) {
  return request({
    url: '/fileInfo/findFileInfo',
    method: 'get',
    params: data
  })
}

export function finish(data) {
  return request({
    url: '/watch/add',
    method: 'post',
    params: data
  })
}

export function hot(data) {
  return request({
    url: '/fileInfo/getHotList',
    method: 'post',
    params: data
  })
}

export function type(data) {
  return request({
    url: '/type/getParamList',
    method: 'post',
    params: data
  })
}

export function code(data) {
  return request({
    url: '/shortmsg/getVerificationCode',
    method: 'post',
    params: data
  })
}

export function register(data) {
  return request({
    url: '/admin/appRegister',
    method: 'post',
    params: data
  })
}


// // 查询app用户列表
// export function listAppUser(query) {
//   return request({
//     url: '/app/appUser/list',
//     method: 'get',
//     params: query
//   })
// }