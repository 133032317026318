<template>
  <div class="login">
    <div class="top">
      <div class="header">
        <div class="logo">
          <img src="../img/logo.png" alt="">

        </div>
        <!-- <div class="options">
        <div class="option_item">首页</div>
        <div class="option_item">课程</div>
        <div class="option_item">注册/登录</div>
      </div> -->
      </div>
      <div class="form">
        <div class="icon">
          <img src="../img/login.png" alt="">
        </div>
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-input v-model="form.saccount" placeholder="请输入手机号"></el-input>
          </el-form-item>

          <el-form-item>
            <el-input v-model="form.msgvalidcode" placeholder="请输入验证码">
              <template slot="suffix">
                <div class="code" @click="getCode">
                  <span v-if="show">获取验证码</span>
                  <span v-else>{{ count }}</span>
                </div>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-input show-password v-model="form.spassword" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input show-password v-model="form.passwordRe" placeholder="重复密码"></el-input>
          </el-form-item>

          <el-button round type="warning" @click="submit">注册</el-button>

        </el-form>
      </div>

      <div class="img">
        <img src="../img/login-icon.png" alt="">
      </div>
    </div>


    <div class="footer">
      <div class="logo">
        <img src="../img/logo.png" alt="">
      </div>
      <div class="footer_btm">
        <!-- <span>联系电话:184-5555555</span> -->
        <span>备案号:滇ICP备2024024619号</span>

      </div>
    </div>
  </div>
</template>

<script>
  import {
    register,
    code
  } from "../axios/api";
  export default {
    data() {
      return {
        form: {
          saccount: '15003835923',
          idfycode:'',
          msgvalidcode: '',
          spassword: '',
          passwordRe:''
        },
        count: 60,
        show: true,
        timer: null,
      }
    },
    methods: {
      getCode() {
        if(this.form.saccount == '') {
          this.$message.error('请输入手机号');
          return
        }
        if (!/^1[35789]\d{9}$/.test(this.form.saccount)) {
          this.$message.error('请输入正确的手机号');
          return
        }
        // 验证码倒计时
        if (!this.timer) {
          code({
            sphone: this.form.saccount
          }).then(res => {
            this.form.idfycode = res.data
            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          })
        }

      },
      submit() {
        register(this.form).then(res=>{
          if(res.code == 200) {
            this.$router.push({
              name: 'index'
        })
          }
        })
      },
    }
  }

</script>


<style lang="scss">
  .login {
    .top {
      position: relative;
      height: 80vh;
      background-color: #82C6F2;
    }

    .header {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0 166px;
      background-color: #82C6F2;

      .logo {
        width: 200px;
        height: 40px;

        // background-color: red;
        img {
          width: 200px;
          height: 40px;
        }

      }

      // .options {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;

      //   .option_item {
      //     padding: 0 30px;
      //     font-size: 16px;
      //     color: #fff;
      //     cursor: pointer;
      //   }
      // }
    }

    .form {
      position: relative;
      z-index: 2;
      box-sizing: border-box;
      padding: 177px 0 0 166px;
      width: 730px;

      .icon {
        margin-bottom: 20px;

        img {
          height: 36px;
        }
      }

      .code {
        width: 150px;
        height: 50px;
        line-height: 50px;
        margin-right: -10px;
        color: #fff;
        background-color: #DE8507;
        border-radius: 25px;
        cursor: pointer;

      }

      .el-input__inner {
        height: 50px !important;
        // padding-right: 0!important;
        border-radius: 25px;
      }

      .el-button {
        width: 80%;
        height: 50px;
        border-radius: 25px;
      }
    }

    .img {
      position: absolute;
      right: 100px;
      bottom: 0;
      z-index: 1;

      img {
        width: 700px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 20vh;
      background-color: #565656;

      .logo {
        width: 200px;
        height: 40px;

        // background-color: red;
        img {
          width: 200px;
          height: 40px;
        }
      }

      .footer_btm {

        margin-top: 50px;

        span {
          margin: 0 49px;
          color: #FFFFFF;
        }
      }
    }
  }

</style>
