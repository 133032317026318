import Vue from 'vue';
import VueRouter from 'vue-router';

import index from '../views/index.vue';
import login from '../views/login.vue';
// import register from '../views/register.vue';
import course from '../views/course.vue';



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: register
  // },
  {
    path: '/course',
    name: 'course',
    component: course
  }
];

const router = new VueRouter({
  routes,
});

export default router;
