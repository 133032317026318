<template>
  <div class="index">
    <div class="header">
      <div class="logo">
        <img src="../img/logo.png" alt="">
      </div>
      <div class="options">
        <div class="option_item" @click="navTo(1)">首页</div>
        <div class="option_item" style="color: #2300FF;">课程</div>
        <div class="option_item" @click="dialogVisible = true">注册/登录</div>
      </div>
    </div>
    <div class="center">
      <div class="load">
        <div class="icon">
          <img src="../img/load.png" alt="">
        </div>
        <div class="screen_list">
          <div class="screen_item">
            <el-select v-model="filtrateValue1" placeholder="请选择" @blur="selcetClick">
              <el-option v-for="item in filtrateList1" :key="item.id" :label="item.bureauname"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="screen_item">
            <el-select v-model="filtrateValue2" placeholder="请选择" @blur="selcetClick">
              <el-option v-for="item in filtrateList2" :key="item.id" :label="item.subjectname"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="screen_item">
            <el-select v-model="filtrateValue3" placeholder="请选择" @blur="selcetClick">
              <el-option v-for="item in filtrateList3" :key="item.id" :label="item.typename"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="screen_item">
            <el-select v-model="filtrateValue4" placeholder="请选择" @blur="selcetClick">
              <el-option v-for="(item,i) in filtrateList4" :key="i" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="select">
          <el-button type="danger" @click="reset">重置</el-button>
        </div>
      </div>

      <div class="goods_list">
        <div class="goods_item" v-for="(item,i) in list" :key="i">
          <div class="img">
            <img :src="item.simgurl" alt="" v-if="item.simgurl">
            <img src="../img/goods.png" alt="" v-else>
          </div>
          <div class="name">{{ item.titleAlter }}</div>
          <div class="hint">{{ item.fileSize }} | {{item.watchNum}}人下载</div>
          <div class="btn" @click="downLoad(item.id)">立即下载</div>
        </div>
      </div>

      <div class="pagin">
        <el-pagination background layout="prev, pager, next" :page-size="12" :total="total"
          :current-page="page" @size-change="handleSizeChange"  @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </div>
    <div class="footer">
      <div class="logo">
        <img src="../img/logo.png" alt="">
      </div>
      <div class="footer_btm">
        <!-- <span>联系电话:184-5555555</span> -->
        <span>备案号:滇ICP备2024024619号</span>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="48%" :show-close="false"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="popup">
        <div class="close" @click="dialogVisible = false">
          <i class="el-icon-close"></i>
        </div>
        <div class="logo">
          <img src="../img/logo.png" alt="">
        </div>
        <div class="icon">
          <img src="../img/icon1.png" alt="">
        </div>
        <div class="form">
          <div class="title">登录</div>
          <div class="type">
            <!-- <el-button round>微信登录</el-button> -->
            <el-button round v-if="type == 2" @click="type = 1">账号密码登录</el-button>
            <el-button round v-if="type == 1" @click="type = 2">手机验证码登录</el-button>
          </div>
          <div class="or">
            -or-
          </div>
          <div class="login" v-if="type == 1">
            <el-form ref="form" :model="form">
              <el-form-item>
                <el-input v-model="form.saccount" placeholder="请输入注册时的手机号"></el-input>
              </el-form-item>

              <el-form-item>
                <el-input show-password v-model="form.spassword" placeholder="请输入密码"></el-input>
              </el-form-item>

              <el-form-item>
                <el-input v-model="form.code" placeholder="请输入图形验证码">
                  <div class="code" slot="append" @click="getImgCode">
                    <img :src="imgInfo" alt="" style="height: 50px;">
                  </div>
                </el-input>
              </el-form-item>

              <el-button round type="warning" @click="submit">立即登录</el-button>

            </el-form>
          </div>
          <div class="login login2" v-if="type == 2">
            <el-form ref="form2" :model="form2">
              <el-form-item>
                <el-input v-model="form2.saccount" placeholder="请输入手机号"></el-input>
              </el-form-item>

              <el-form-item>
                <el-input v-model="form2.msgvalidcode" placeholder="请输入验证码">
                  <template slot="suffix">
                    <div class="code2" @click="getCode">
                      <span v-if="show">获取验证码</span>
                      <span v-else>{{ count }}</span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
              <el-button round type="warning" @click="submit2">立即登录</el-button>

            </el-form>
          </div>
          <div class="tips">
            还没有账号？<span @click="navTo(2)">立即注册</span>
          </div>
        </div>
      </div>
    </el-dialog>


    <Vcode :show="isShow" @success="success" />
  </div>
</template>

<script>
  import {
    login,
    imgCode,
    nation,
    filtrate1,
    filtrate2,
    list,
    load,
    finish,
    hot,
    type,
    code,
    login2
  } from "../axios/api";
  import {
    setToken,
    getToken
  } from '../axios/getToken'

  import Vcode from "vue-puzzle-vcode";
  export default {
    components: {
      Vcode
    },
    data() {
      return {
        isShow: false,
        form: {
          saccount: '',
          spassword: '',
          code: '',
          uuid: '',
        },
        count: 60,
        show: true,
        timer: null,
        form2: {
          saccount: '',
          msgvalidcode:'',
          idfycode:'',
        },
        type:1,
        imgInfo: '',
        uuid: '',
        options: [], //国家
        filtrateActive: 1,
        filtrateList1: [], //考试局
        filtrateList2: [], //学科
        filtrateList3: [], //类别
        filtrateList4: ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016',
          '2015'
        ], //年份
        filtrateValue1: '',
        filtrateValue2: '',
        filtrateValue3: '',
        filtrateValue4: '',
        value: '',
        dialogVisible: false,
        list: [],
        page: 1,
        total: 0,
        id: '',
        hotList: [],
        bannerImg: 'https://kaoshipdf.oss-cn-shanghai.aliyuncs.com/118510d36d3f4d5abf7ed18fe5f8430f.png?Expires=3286505144&OSSAccessKeyId=LTAI5tK22d4CAWCRWBAsrF8A&Signature=jou5kFyEJGpb8xeJNTugMwZBI%2Bo%3D'
      }
    },
    created() {
      this.getImgCode()
      //this.getNation()
      this.getFiltrateList1()
      this.getFiltrateList2()
      // this.getHot()
      this.getType()
    },
    methods: {
        // 验证码
        getCode() {
        if (this.form2.saccount == '') {
          this.$message.error('请输入手机号');
          return
        }
        if (!/^1[35789]\d{9}$/.test(this.form2.saccount)) {
          this.$message.error('请输入正确的手机号');
          return
        }
        // 验证码倒计时
        if (!this.timer) {
          code({
            sphone: this.form2.saccount
          }).then(res => {
            this.form2.idfycode = res.data
            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          })
        }

      },
      submit2() {
        login2(this.form2).then(res=>{
          if(res.code == 200) {
            setToken('userId', res.data.id)
            setToken('token', res.data.token)
            this.dialogVisible = false
          }
          console.log(res);
        })
      },
      /**
       * 获取图形验证码
       */
      getImgCode() {
        imgCode().then(res => {
          this.imgInfo = 'data:image/gif;base64,' + res.img
          this.uuid = res.uuid
          console.log(res);
        })
      },
      /**
       * 获取热门试卷
       */
      getHot() {
        hot().then(res => {
          this.hotList = res.rows.splice(0, 2)
          console.log(this.hotList);
        })
      },
      /**
       * 获取国家
       */
      // getNation() {
      //   nation().then(res => {
      //     this.options = res.rows
      //     console.log(res);
      //   })
      // },
      /**
       * 获取考试局
       */
      getFiltrateList1() {
        filtrate1().then(res => {
          this.filtrateList1 = res.rows
          this.filtrateValue1 = res.rows[0].id
          this.getList()
        })
      },
      /**
       * 获取考试局
       */
      getType() {
        type().then(res => {
          this.filtrateList3 = res.rows
        })
      },
      /**
       * 获取学科
       */
      getFiltrateList2() {
        filtrate2().then(res => {
          this.filtrateList2 = res.rows
        })
      },

      selcetClick() {
        setTimeout(() => {
          this.getList()
        }, 100)
      },
      // screenClick(i, item) {
      //   if (i == 1) {
      //     if (this.filtrateValue1 == item.id) {
      //       this.filtrateValue1 = ''
      //     } else {
      //       this.filtrateValue1 = item.id
      //     }
      //   } else if (i == 2) {
      //     if (this.filtrateValue2 == item.id) {
      //       this.filtrateValue2 = ''
      //     } else {
      //       this.filtrateValue2 = item.id

      //     }
      //   } else if (i == 3) {
      //     if (this.filtrateValue3 == item.id) {
      //       this.filtrateValue3 = ''
      //     } else {
      //       this.filtrateValue3 = item.id
      //     }

      //   } else {
      //     if (this.filtrateValue4 == item) {
      //       this.filtrateValue4 = ''
      //     } else {
      //       this.filtrateValue4 = item
      //     }

      //   }
      //   this.getList()
      // },
      /**
       * 获取试卷列表
       */
      getList() {
        list({
          // countryid: this.value,
          bureauid: this.filtrateValue1,
          subjectid: this.filtrateValue2,
          typeid: this.filtrateValue3,
          syear: this.filtrateValue4,
          pageSize: 12,
          pageNum: this.page
        }).then(res => {
          this.list = res.rows
          this.total = res.total
        })
      },
      reset() {
        this.page = 1
        this.filtrateValue1 = this.filtrateList1[0].id
        this.filtrateValue2 = ''
        this.filtrateValue3 = ''
        this.filtrateValue4 = ''
        this.filtrateActive = 1
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      // 下载
      downLoad(id) {
        if (!getToken('token')) {
          this.$message.error('当前操作需要登陆');
          setTimeout(() => {
            this.dialogVisible = true
          }, 500)
        } else {
          this.isShow = true
          this.id = id
        }

      },
      success(msg) {
        load({
          id: this.id,
          customerid: getToken('userId')
        }).then(res => {
          if (res.code == 401) {
            this.$message.error('登陆已过期，请重新登陆');
            setTimeout(() => {
              this.dialogVisible = true
            }, 500)
          } else if (res.code == 200) {
            // console.log(res);
            location.href = res.msg
            this.aa()
          }
          this.isShow = false
          console.log(res);
        })
      },
      aa() {
        finish({
          infoid: this.id,
          customerid: getToken('userId')
        }).then(res => {
          console.log(res);
        })
      },
      /**
       * 登录
       */
      submit() {
        this.form.uuid = this.uuid
        login(this.form).then(res => {
          if (res.code == 200) {
            setToken('userId', res.data.id)
            setToken('token', res.data.token)
            this.dialogVisible = false
          }
          console.log(res);
        })
      },
      navTo(i) {
        if (i == 1) {
          this.$router.push({
            name: 'index'
          })
        } else {
          this.$router.push({
            name: 'login'
          })
        }

      },
    }
  };

</script>

<style lang="scss">
  .index {
    background-color: #F6FCFF;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0 166px;
      background-color: #82C6F2;

      .logo {
        width: 200px;
        height: 40px;

        // background-color: red;
        img {
          width: 200px;
          height: 40px;
        }
      }

      .options {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .option_item {
          padding: 0 30px;
          font-size: 16px;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .center {
      .load {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 212px;
        padding: 0 8%;
        background: url('../img/load_bg.png') no-repeat;
        background-size: cover;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          img {
            width: 188px;
            height: 36px;
          }
        }

        .screen_list {
          display: flex;
          justify-content: flex-start;

          .active {
            color: #2300FF !important;
          }

          .screen_item {
            font-size: 20px;
            padding-top: 160px;
            margin-left: 76px;
            color: #fff;
            cursor: pointer;

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .select {
          padding-top: 160px;

          .el-input__inner {
            height: 62px !important;
            width: 160px !important;
            border-radius: 30px !important;
          }
        }
      }


      .screen {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 28px 8%;
        margin-top: 20px;
        background-color: #fff;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);

        .active {
          color: #DE8507 !important;
        }

        .triangle {
          position: absolute;
          top: -40px;
          display: inline-block;
          width: 0px;
          height: 0px;
          border: 20px solid #fff; //白色
          border-top-color: transparent;
          border-bottom-color: #eee;
          border-left-color: transparent;
          border-right-color: transparent;

        }

        .item {
          width: 20%;
          line-height: 40px;
          // text-align: center;
          color: #7A7A7A;
          cursor: pointer;

        }
      }

      .goods_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
        padding: 0 8% 30px;

        .goods_item {
          height: 480px;
          width: 20%;
          margin-right: 3%;
          margin-bottom: 60px;
          padding: 16px 11px;
          background-color: #fff;
          box-shadow: 0px 3px 6px 0px rgba(204, 204, 204, 0.1608);
          border-radius: 20px;
          border: 1px solid #C4C4C4;

          // &:hover {
          //   background-color: #82C6F2;

          //   .name {
          //     color: #fff;

          //     span {
          //       background-color: #F6FCFF;
          //     }
          //   }

          //   .hint {
          //     color: #FFFFFF;
          //   }

          //   .btn {
          //     background-color: #fff;
          //     color: #82C6F2;
          //   }
          // }

          &:nth-child(4n) {
            margin-right: 0;
          }

          .img {
            height: 288px;

            img {
              width: 100%;
              height: 288px;
            }
          }

          .name {
            position: relative;
            margin-top: 22px;
            text-align: center;
            font-size: 20px;
            color: #565656;

            span {
              position: absolute;
              bottom: -2px;
              left: 50%;
              transform: translateX(-50%);
              height: 10px;
              width: 200px;
              background-color: #FF9100;
              opacity: 0.4;
            }
          }

          .hint {
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            color: #ccc;
          }

          .btn {
            height: 54px;
            cursor: pointer;
            margin-top: 30px;
            text-align: center;
            line-height: 54px;
            border-radius: 10px;
            font-size: 16px;
            color: #fff;
            background-color: #82C6F2;
          }
        }
      }


      .pagin {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 211px;
      background-color: #565656;

      .logo {
        width: 200px;
        height: 40px;
        text-align: center;

        // background-color: red;
        img {
          width: 200px;
          height: 40px;
        }
      }

      .footer_btm {

        margin-top: 50px;

        span {
          margin: 0 49px;
          color: #FFFFFF;
        }
      }
    }
  }

  .popup {
    position: relative;
    box-sizing: border-box;
    position: relative;
    height: 652px;
    padding: 56px 47px;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 999;
      font-size: 20px;
    }

    .logo {
      text-align: center;

      width: 200px;
      height: 40px;

      // background-color: red;
      img {
        width: 200px;
        height: 40px;
      }
    }

    .icon {
      position: absolute;
      left: 7%;
      bottom: 0;
      z-index: 99;

      img {
        width: 280px;
        // height: 400px;
      }
    }

    .form {
      box-sizing: border-box;
      position: absolute;
      right: 0;
      top: 0;
      width: 70%;
      height: 100%;
      padding: 98px 52px;
      background-color: #fff;
      border-radius: 20px 0 0 20px;

      .el-input-group__append {
        padding: 0 !important;
        border: none !important;
      }

      .code {
        img {
          font-size: 0;
        }
      }

      .title {
        font-size: 30px;
      }

      .type {
        margin-top: 20px;

        .el-button {
          width: 45%;
        }
      }

      .or {
        margin: 29px 0;
        font-size: 20px;
        text-align: center;
        color: #808080;
      }

      .login {
        padding: 0 60px;

        .el-input__inner {
          border: none !important;
          height: 60px !important;
          border-bottom: 1px solid #000 !important;
          border-radius: 0 !important;
        }

        .el-input .el-input__clear {
          font-size: 20px !important;
          color: #000 !important;
        }

        .el-button {
          width: 100%;
        }

        .tips {
          margin-top: 20px;
          cursor: pointer;

          span {
            color: #82C6F2;
          }
        }
      }
    }
  }

  .el-dialog {
    background-color: #82C6F2 !important;

  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;

  }



  .el-pager li {
    padding: 0 25px !important;
    height: 60px !important;
    line-height: 60px !important;
  }

  .el-pagination button {
    padding: 0 25px !important;
    height: 60px !important;
    line-height: 60px !important;
  }

  .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    font-size: 20px !important;
  }

</style>
